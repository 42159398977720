import React from "react"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../../components/layout.component"
import Seo from "../../components/seo.component"

import imgSerHeroLogo from "../../images/ser-hero-logo.png"
import imgSerLogo from "../../images/course-logo-ser.jpg"
import imgViolins from "../../images/violins.jpg"
import imgStudents from "../../images/students-playing.jpg"
import imgKatherineEmeneth from "../../images/katerhine-emeneth.jpg"

import "./studio-ensemble-revolution.styles.scss"

const PricingComponent = () => (
  <div className="ser__price has-box-shadow is-flex is-flex-direction-column is-flex-grow-1 is-75-percent-mobile-xl has-text-centered">
    <figure className="image">
      <img
        src={imgSerLogo}
        alt=""
        role="presentation"
        className="is-flex-grow-0"
      />
    </figure>
    <div className="price__content content is-flex-grow-1 is-size-5 mt-2">
      <h2>Studio Ensemble Revolution</h2>
      <p>
        <sup>$</sup>
        <span className="price">174.99</span>
      </p>
      <a
        className="button is-info is-uppercase is-fullwidth has-text-weight-semibold"
        href="https://www.udemy.com/course/studio-ensemble-revolution/?referralCode=C7A8B5D9A746E73401F2"
        target="_blank"
        rel="noreferrer"
      >
        Purhcase Now on Udemy
      </a>
    </div>
  </div>
)

const CoursesPage = ({ location }) => (
  <Layout className="ser" pathname={location.pathname}>
    <Seo
      title="Studio Ensemble Revolution"
      description="Studio Ensemble Revolution is a series of coaching sessions that teach you how to start and manage an in-person studio ensemble program."
    />
    <section className="hero ser__hero is-medium">
      <div className="container hero-body">
        <img src={imgSerHeroLogo} alt="Studio Ensemble Revolution" />
      </div>
    </section>
    <section className="ser__introducing pt-4 pb-6">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="content is-size-6">
              <h1 className="title is-size-4-mobile is-size-3-tablet has-border-left-primary-2 mb-6">
                Are you ready to build community for your students through a
                comprehensive studio ensemble program while generating
                additional income?
              </h1>
              <p>
                <strong>Studio Ensemble Revolution</strong> is an online course
                hosted through Udemy that features instructional videos,
                templates and downloadable content that teachs you how to start
                and manage a studio ensemble program.
              </p>
              <p>
                Playing in an ensemble produces memories and experiences that
                have staying power. Be front and center in creating that
                atmosphere for your students because they will appreciate it for
                years to come!
              </p>
              <h3>Who's it for?</h3>
              <p>
                Studio Ensemble Revolution is for ANY music teacher who is
                searching for a way to launch or expand a studio ensemble
                program.
              </p>
              <p>
                Not quite ready to meet or rehearse in person? Connecting online
                for ensemble rehearsals is doable and works for both small AND
                large ensembles. (I’m talking about meeting with 20+
                participants here!)
              </p>
              <p>
                Concerned about the investment? Establish one studio quartet
                that meets for 10 rehearsals and Studio Ensemble Revolution can
                pay for itself once you learn how to set tuition for an
                ensemble.
              </p>
              <p>
                If you don't think you have time to add an ensemble component to
                your studio - I promise that you do, and it is ABSOLUTELY worth
                your time.
              </p>
              <h3>How much is it?</h3>
              <p>
                The investment for Studio Ensemble Revolution is $174.99 and you
                will have lifetime access to the course and content.
              </p>
            </div>
          </div>
          <div className="column is-one-third is-flex is-align-items-center">
            <figure className="image is-75-percent-mobile-xl">
              <img
                src={imgViolins}
                alt=""
                role="presentation"
                className="has-border-radius-2 has-box-shadow"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
    {/* <section className="ser__pricing has-background-grey-lighter py-6">
      <PricingComponent />
    </section> */}
    <section
      className="ser__how-it-works py-6"
      style={{ background: "hsl(0, 0%, 94%)" }}
    >
      <div className="container container--text">
        <div className="content is-size-6 mt-6">
          <h2>How Does it Work?</h2>
          <p>
            Studio Ensemble Revolution is an online course hosted through Udemy
            that features instructional videos, templates and downloadable
            content for you to work through at your own pace. The following
            topics will be explored:
          </p>
          <ul>
            <li>The Fundamentals (Mindset and Organization)</li>
            <li>Finances (Setting Tuition and Expenses)</li>
            <li>Making it Happen (Rehearsals, Term Structure and More)</li>
            <li>Growth (Marketing and Generating Buzz)</li>
            <li>Integrating Online Instruction</li>
          </ul>
          <figure className="image has-text-centered ml-0 mr-0">
            <img
              src={imgStudents}
              alt=""
              className="has-box-shadow has-border-radius-2 is-75-percent-mobile-xl"
            />
          </figure>
        </div>
      </div>
    </section>
    <section className="ser__highlights has-background-info py-6">
      <div className="container">
        <h2 className="is-size-3-mobile is-size-2-tablet pb-6 has-text-centered">
          Program Highlights
        </h2>
        <div className="columns is-centered">
          <div className="column is-full-mobile is-one-third-tablet">
            <div className="card">
              <div className="content has-text-centered">
                <h3 className="has-text-primary is-size-3-mobile is-size-4-tablet is-size-2-widescreen">
                  Community
                </h3>
                <p className="is-size-5-mobile is-size-6-tablet is-size-5-desktop">
                  Build a community within your studio that keeps students
                  engaged and coming back for more.
                </p>
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-one-third-tablet">
            <div className="card">
              <div className="content has-text-centered">
                <h3 className="has-text-primary is-size-3-mobile is-size-4-tablet is-size-2-widescreen">
                  Online Rehearsals
                </h3>
                <p className="is-size-5-mobile is-size-6-tablet is-size-5-desktop">
                  Yes it is possible! Uncover exactly how to run a full
                  rehearsal online for any number of students.
                </p>
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-one-third-tablet">
            <div className="card">
              <div className="content has-text-centered">
                <h3 className="has-text-primary is-size-3-mobile is-size-4-tablet is-size-2-widescreen">
                  Resources
                </h3>
                <p className="is-size-5-mobile is-size-6-tablet is-size-5-desktop">
                  Gain accesss to everything you need to launch a studio
                  ensemble program from scratch.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ser__testimonial py-6">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop">
            <div className="content is-size-4-mobile is-size-5-tablet is-size-4-desktop p-4">
              <p>
                <div className="styled-quote-symbol has-text-primary">
                  &ldquo;
                </div>
                <em>Dan Parasky's</em> Studio Ensemble Revolution{" "}
                <em>
                  is THE solution if you're interested in creating an ensemble
                  program for your lessons studio whether it is online or in
                  person. Dan clearly lays out the step-by-step system of how to
                  get students interested in participating in an ensemble
                  program as well as how ot structure fees, go about finding a
                  rehearsal space, and so much more. He also covers how having
                  an ensemble program helps retention and creates studio
                  culture.
                </em>
              </p>
              <p>
                <em>
                  If you're looking for a way to add extra income to your studio
                  endeavors and love chamber music, I definitely recommend
                  taking Studio Ensemble Revolution. You won't regret it!
                </em>
                <div className="styled-quote-symbol has-text-primary has-text-right">
                  &rdquo;
                </div>
              </p>
              <p className="has-text-right has-text-weight-semibold">
                - Katherine Emeneth
              </p>
            </div>
          </div>
          <div className="column is-full-mobile is-one-third-tablet is-one-quarter-desktop">
            <figure className="image is-75-percent-mobile-xl">
              <img
                src={imgKatherineEmeneth}
                alt=""
                role="presentation"
                className="has-border-radius-2 has-box-shadow"
              />
            </figure>
          </div>
        </div>
        <div className="columns columns--reverse is-centered mt-6">
          <div className="column is-full-mobile is-one-third-tablet is-one-quarter-desktop">
            <figure className="image is-75-percent-mobile-xl">
              <img
                src={imgSerLogo}
                alt=""
                role="presentation"
                className="has-border-radius-2 has-box-shadow"
              />
            </figure>
          </div>
          <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop">
            <div className="content is-size-4-mobile is-size-5-tablet is-size-4-desktop p-4">
              <p>
                <div className="styled-quote-symbol has-text-primary">
                  &ldquo;
                </div>
                <em>
                  I was so grateful to have found Dan Parasky's Studio Ensemble
                  Revolution class last year. I learned so many valuable skills
                  that I now use each and every week in my own flute studio. Not
                  only did I learn how to use a new technology, but also how to
                  apply it to building a bona fide studio ensemble program that
                  doesn't solely depend on an in-person rehearsal. It doesn't
                  even stop there...I also learned how to run an efficient and
                  effective rehearsal in an online format, how best to organize
                  the pre-work, how to price an ensemble program and even how to
                  get the best out of an online rehearsal format, something that
                  was completely foreign to me before. Dan is so patient, takes
                  his time with the content and really lays out an amazing
                  strategy for how to add this to my studio. I even use this
                  format with my private lesson students who are still online
                  today. We are able to still benefit from things like duets all
                  because of the skills I learned in SER Class. I'm lucky to
                  have found the class and I highly recommend this for anyone
                  looking to add a level of awesome to their normal studio
                  activities. It's a wonderful class and Dan is a wonderful
                  teacher.
                </em>
              </p>
              <p>
                <em>
                  If you're looking for a way to add extra income to your studio
                  endeavors and love chamber music, I definitely recommend
                  taking Studio Ensemble Revolution. You won't regret it!"
                </em>
                <div className="styled-quote-symbol has-text-primary has-text-right">
                  &rdquo;
                </div>
              </p>
              <p className="has-text-right">
                <span className="has-text-weight-semibold">- Sydnee Stein</span>
                <br />
                <span className="has-text-grey">
                  2020 Studio Ensemble Revolution Participant
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ser__videos has-background-grey-lighter py-6">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <div className="video-container">
              <iframe
                src="https://www.youtube.com/embed/70PpDz-qN1o"
                title="Russian Dance from The Nutcracker...Tchaikovsky (arr. Ochoco)"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="column is-half p-4 is-flex is-align-items-center">
            <div className="content is-size-6">
              <h3>Grow Your Program</h3>
              <p>
                Take a journey with me to see how the Parasky Flute Studios
                ensemble program started with only 8 students and now involves
                the entire studio.
              </p>
              <ul>
                <li>Studio Flute Orchestra</li>
                <li>Honors Flute Choir</li>
                <li>6 small ensembles</li>
              </ul>
              <a
                href="#ready-to-join"
                className="button is-primary arrow-right-to-down"
              >
                Let's Get Started! <FaArrowRight className="ml-3" />
              </a>
            </div>
          </div>
        </div>
        <div className="columns mt-6">
          <div className="column is-half p-4 is-flex is-align-items-center">
            <div className="content is-size-6">
              <h3>Virtual Performances</h3>
              <p>
                Learn how to do all the back-end work required to make a virtual
                performance possible.
              </p>
              <p>
                Audio/Video editing IS NOT COVERED in Studio Ensemble
                Revolution, but you will be given a list of contacts available
                for hire as a part of the course resources.
              </p>
              <p>
                These students in this video never met or rehearsed in person
                &mdash; everything was done online over a weekend festival.
              </p>
            </div>
          </div>
          <div className="column is-half">
            <div className="video-container">
              <iframe
                src="https://www.youtube.com/embed/R5X6j8zaINw"
                title="Scarborough Fair...Traditional English, arr. Amy Rice-Young (b. 1952)"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="pricing" className="ser__ready-to-join py-6">
      <div className="container">
        <div className="columns is-justify-content-center">
          <div className="column is-full-mobile is-half-tablet is-one-third-widescreen is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
            <h2
              className="is-size-2-mobile is-size-1-tablet has-text-white has-text-shadow py-2"
              id="ready-to-join"
            >
              Ready to join?
            </h2>
            <p className="is-size-5 has-text-white has-text-shadow has-text-centered py-2">
              Enjoy knowing you're getting the best information out there about
              studio ensemble building.
            </p>
          </div>
          <div className="column is-full-mobile is-half-tablet is-one-third-widescreen">
            <PricingComponent />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default CoursesPage
